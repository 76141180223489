import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// Components
import ContentHeader from '../components/ContentHeader';
import BalancingCard from '../components/BalancingCard';
import Link from '../components/Link';

// Assets
import libraryAnimation from '../animations/archive.json';

export const query = graphql`
  query ArchiveQuery {
    file(name: { eq: "justitia-banner" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          height: 600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    allYears(
      filter: { published: { eq: true } }
      sort: { order: DESC, fields: [year] }
    ) {
      nodes {
        year
        theme
        color
        banner {
          childImageSharp {
            gatsbyImageData(height: 300)
          }
        }
        dates {
          begin
          end
        }
      }
    }
  }
`;

type ArchivePageProps = {
  data: any;
};

export default class ArchivePage extends React.Component<ArchivePageProps> {
  render() {
    return (
      <div className="flex flex-col items-center">
        <ContentHeader
          title="Archief"
          background={this.props.data.file.childImageSharp.gatsbyImageData}
          animationSize="200%"
          backLink="/"
          backLabel="Overzicht"
          colored_banner={true}
        />
        <div className="grid md:grid-cols-2 gap-6 w-full max-w-screen-md py-24 px-6">
          {this.props.data.allYears.nodes.map((year: any, index: number) => {
            const banner = getImage(year.banner);
            return (
              <Link to={`/${year.year}`} key={index}>
                <BalancingCard>
                  <div
                    className="flex flex-col items-center w-full px-16 py-12 rounded-xl overflow-hidden relative text-white hover:opacity-80 active:opacity-50 transition-opacity"
                    style={{
                      backgroundColor: year.color,
                    }}
                  >
                    {banner ? (
                      <div className="absolute top-0 left-0 w-full h-full opacity-20 z-10">
                        <GatsbyImage
                          image={banner}
                          alt={year.theme}
                          className="absolute top-0 left-0 w-full h-full object-cover rounded-xl"
                        />
                      </div>
                    ) : null}
                    <div className="relative z-20 flex flex-col items-center gap-2">
                      <h2 className="text-5xl font-black">{year.year}</h2>
                      <p className="text-2xl font-bold">
                        {year.theme ?? 'TBA'}
                      </p>
                    </div>
                  </div>
                </BalancingCard>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

export function Head() {
  return <title>Archief - Stichting Justitia</title>;
}
